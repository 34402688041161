import { AuthActionTypes } from "src/redux";

const types: AuthActionTypes = {
  EXCHANGE_CODE_FOR_TOKENS_PENDING: "EXCHANGE_CODE_FOR_TOKENS_PENDING",
  EXCHANGE_CODE_FOR_TOKENS_SUCCESS: "EXCHANGE_CODE_FOR_TOKENS_SUCCESS",
  EXCHANGE_CODE_FOR_TOKENS_ERROR: "EXCHANGE_CODE_FOR_TOKENS_ERROR",
  SET_USER_DATA: "SET_USER_DATA",
  REFRESH_TOKEN_PENDING: "REFRESH_TOKEN_PENDING",
  REFRESH_TOKEN_ERROR: "REFRESH_TOKEN_ERROR",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
};

export default types;
