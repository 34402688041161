export const breakpoints = {
  // each value is the bottom threshold (included)
  sm: "0px",
  md: "768px",
  lg: "1366px",
};

export const spacing = {
  xsmall: "4px",
  base: "8px",
  small: "16px",
  medium: "24px",
  large: "32px",
  midlarge: "40px",
  xlarge: "48px",
  xxlarge: "64px",
  ultra: "80px",
};

export const padding = {
  xsmall: "4px",
  small: "8px",
  medium: "12px",
  large: "16px",
  xlarge: "20px",
  xxlarge: "24px",
};

export const margin = {
  xsmall: "4px",
  small: "8px",
  medium: "12px",
  large: "16px",
  xlarge: "20px",
};

export const height = {
  xxsmall: "16px",
  xsmall: "24px",
  small: "32px",
  medium: "40px",
  large: "48px",
  xlarge: "56px",
  xxlarge: "64px",
};

export const namedZIndex = {
  mainHeader: 999,
  leftSidebar: 1000,
  starrySkyPlanet: 49,
  starrySkyMoon: 50,
  starrySkyUfo: 51,
  careerTimeline: 52,
  homeWorldMap: 52,
};
