import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    ul {
        background-color: inherit;
        .MuiListItemIcon-root {
            min-width: auto;
        }
        .MuiButtonBase-root {
        }
    }
`;
